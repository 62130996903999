import React from 'react';


const About = () => {
  return (
    <div className='MainContainer2'>
      <h5>About Me</h5>
      <p>
        I am mom of beautiful twin girls.I have done MBBS from India and MHSA from Creighton, USA. Currently i am training to become
        a Full Stack Web Developer at Lambda School.This is my practice junkyard where I learn and test things.
      </p>
    </div>
  );
};


export default About;




